// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-articles-page-template-js": () => import("./../src/templates/ArticlesPageTemplate.js" /* webpackChunkName: "component---src-templates-articles-page-template-js" */),
  "component---src-templates-article-content-page-template-js": () => import("./../src/templates/ArticleContentPageTemplate.js" /* webpackChunkName: "component---src-templates-article-content-page-template-js" */),
  "component---src-templates-articles-categories-page-template-js": () => import("./../src/templates/ArticlesCategoriesPageTemplate.js" /* webpackChunkName: "component---src-templates-articles-categories-page-template-js" */),
  "component---src-templates-blogroll-page-template-js": () => import("./../src/templates/BlogrollPageTemplate.js" /* webpackChunkName: "component---src-templates-blogroll-page-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

